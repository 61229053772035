import apiClient from "./api";
import { jwtDecode } from "jwt-decode";

const login = (username: string, password: string) => {
  return apiClient
    .post("/api/token/", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.access) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const decodedJWT = (): {
  token_type: string;
  exp: number;
  jti: string;
  user_id: number;
} => {
  return jwtDecode(getCurrentUser().access);
};

const isGuest = (): boolean => {
  const decoded: {
    token_type: string;
    exp: number;
    jti: string;
    user_id: number;
  } = decodedJWT();
  if (decoded.user_id > 2) return true;
  return false;
};
/*const refreshToken = () => {
    const user = localStorage.getItem('user')
    const token = user ? JSON.parse(user).refresh : null
    if (token) return apiClient.post('/api/token/refresh/', token)
    return null
}*/

/*const verifyToken = () => {
    const user = localStorage.getItem('user')
    const token = user ? JSON.parse(user).refresh : null
    return apiClient.post('/api/token/verify/', token)
}*/

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

export { login, logout, getCurrentUser, isGuest };
