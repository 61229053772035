import React, { useCallback, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import WarehouseService from "services/api/warehouse.service";
import {
  Buyer,
  CargoPayer,
  Customer,
  CustomerInput,
  Delivery,
  DeliveryInput,
  Movie,
  MovieInput,
  Product,
  ProductInput,
  ProductType,
  WarehouseInvoice,
} from "../../types/types";
import Form from "react-bootstrap/Form";
import { fi } from "date-fns/locale/fi";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isGuest } from "../../services/api/auth.service";
import ModalForm from "../../components/ModalForm";
import CustomerRowComponent from "../../components/CustomerRowComponent";
import Order from "pages/order/Order";

registerLocale("fi", fi);
setDefaultLocale("fi");
type ModalState = {
  type: string;
  item: string;
  id?: number;
  customer?: number;
  movie?: number;
  product?: number;
  amount?: number;
};

const Dashboard = (props: any) => {
  const [error, setError] = useState(null);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [movies, setMovies] = useState<Movie[]>([]);
  const [filteredMovies, setFilteredMovies] = useState<Movie[]>([]);
  const [searchedMovies, setSearchedMovies] = useState<Movie[]>([]);
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState<Product[]>([]);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [warehouseInvoices, setWarehouseInvoices] = useState<
    WarehouseInvoice[]
  >([]);
  const [deliveries, setDeliveries] = useState<Delivery[]>([]);
  const [modalState, setModalState] = useState<ModalState>({
    id: undefined,
    item: "",
    type: "",
    customer: undefined,
    movie: undefined,
    product: undefined,
  });
  const [show, setShow] = useState(false);
  const [form, setForm] = useState<
    | CustomerInput
    | MovieInput
    | ProductInput
    | DeliveryInput
    | Record<string, unknown>
  >({});
  const [guest, setGuest] = useState(false);
  const [showPast, setShowPast] = useState(false);
  const [buyers, setBuyers] = useState<Buyer[]>([]);
  const [cargoPayers, setCargoPayers] = useState<CargoPayer[]>([]);
  const hostname = window.location.hostname;

  const handleClose = () => {
    setModalState({
      type: "",
      item: "",
      id: undefined,
    });
    setForm({});
    setShow(false);
  };
  const handleShow = (
    type: string,
    item: string,
    id?: number,
    customer?: number,
    movie?: number,
    product?: number,
  ) => {
    setModalState({
      type,
      item,
      id,
    });
    if (item === "customer") {
      if (id) {
        const customer = getCustomerById(id);
        setForm({
          id: id,
          name: customer?.name || "",
          archived: customer?.archived || false,
        });
      } else {
        setForm({});
      }
    }
    if (item === "movie") {
      if (id) {
        const movie = getMovieById(id);
        setForm({
          id: id,
          customer: customer,
          name: movie?.name || "",
          premiere_date: movie?.premiere_date
            ? new Date(movie?.premiere_date).toISOString()
            : new Date().toISOString(),
          secret: movie?.secret || "",
        });
      } else {
        setForm({
          customer: customer,
          premiere_date: new Date().toISOString(),
          product: {
            product_type: "1",
            arrival_date: new Date().toISOString(),
          },
        });
      }
    }
    if (item === "product") {
      if (id) {
        const product = getProductById(id);
        setForm({
          id: id,
          product_type: product?.product_type.id,
          movie: product?.movie.id,
          current_stock: product?.current_stock,
          arrival_date: product?.arrival_date,
          shelving_location: product?.shelving_location,
          amount: 0,
        });
      } else if (type !== "order") {
        setForm({
          product_type: "1",
          movie: movie,
          arrival_date: new Date().toISOString(),
        });
      }
    }
    if (item === "delivery") {
      setForm({
        product: product,
        delivery_date: new Date().toISOString(),
        to: "",
        location_amount: 0,
        amount: 0,
        invoiced: false,
        buyers: buyers,
        cargo_payers: cargoPayers,
      });
    }
    if (item === "product" && type === "order") {
      setForm({
        product: product,
        delivery_date: new Date().toISOString(),
        buyer: "",
        cargo_payer: "",
        to: "",
        location_amount: 0,
        amount: 0,
        invoiced: false,
      });
    }
    setShow(true);
  };

  const onChangeDateValue = (date: Date, field: string) => {
    const newForm = { ...form };
    if (field === "productarrival_date") {
      // @ts-ignore
      if (modalState.item === "movie") {
        // @ts-ignore
        newForm["product"]["arrival_date"] = date.toISOString();
      } // @ts-ignore
      else newForm["arrival_date"] = date.toISOString();
    } else if (field === "delivery_date") {
      // @ts-ignore
      newForm["delivery_date"] = date.toISOString();
    } else {
      // @ts-ignore
      newForm["premiere_date"] = date.toISOString();
    }
    setForm(newForm);
  };

  const onChangeFormValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const newForm = { ...form };
    if (modalState.item === "movie") {
      // @ts-ignore
      if (e.target.id.includes("product")) {
        // @ts-ignore
        newForm["product"][e.target.id.substring(7)] = value;
      } else {
        // @ts-ignore
        newForm[e.target.id] = value;
      }
      setForm(newForm);
    } else if (modalState.item === "product") {
      // @ts-ignore
      newForm[e.target.id.substring(7)] = value;
      setForm(newForm);
    } else {
      // @ts-ignore
      newForm[e.target.id] = value;
      setForm(newForm);
    }
  };

  const onFormSubmit = async () => {
    switch (modalState.item) {
      case "customer":
        const newCustomer = form as CustomerInput;
        if (newCustomer.id) {
          await WarehouseService.editCustomer(form as CustomerInput);
          await WarehouseService.getCustomers().then(
            (response) => {
              response.data.sort(function (a: Customer, b: Customer) {
                let nameA = a.name.toUpperCase();
                let nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }

                // names must be equal
                return 0;
              });
              setCustomers(
                response.data.filter(
                  (customer: Customer) => !customer.archived,
                ),
              );
            },
            (error) => {
              setError(error);
            },
          );
        } else {
          await WarehouseService.addCustomer(form as CustomerInput);
          await WarehouseService.getCustomers().then(
            (response) => {
              response.data.sort(function (a: Customer, b: Customer) {
                let nameA = a.name.toUpperCase();
                let nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }

                // names must be equal
                return 0;
              });
              setCustomers(
                response.data.filter(
                  (customer: Customer) => !customer.archived,
                ),
              );
            },
            (error) => {
              setError(error);
            },
          );
        }
        handleClose();
        break;
      case "movie":
        const newMovie = form as MovieInput;
        if (newMovie.id) {
          await WarehouseService.editMovie(newMovie);
          const movies = await WarehouseService.getMovies();
          setMovies(movies.data);
        } else {
          await WarehouseService.addMovie(form as MovieInput);
          const movies = await WarehouseService.getMovies();
          setMovies(movies.data);
          const products = await WarehouseService.getProducts();
          setProducts(products.data);
        }
        handleClose();
        break;
      case "product":
        const newProduct = form as ProductInput;
        if (newProduct.id) {
          await WarehouseService.editProduct(newProduct);
          const products = await WarehouseService.getProducts();
          setProducts(products.data);
        } else {
          await WarehouseService.addProduct(form as ProductInput);
          const products = await WarehouseService.getProducts();
          setProducts(products.data);
        }
        handleClose();
        break;
      case "delivery":
        await WarehouseService.addDelivery(form as DeliveryInput);
        const deliveries = await WarehouseService.getDeliveries();
        setDeliveries(deliveries.data);
        const products = await WarehouseService.getProducts();
        setProducts(products.data);
        handleClose();
        break;
    }
  };
  useEffect(() => {
    setGuest(isGuest());
    WarehouseService.getBuyers().then(
      (response) => {
        console.log("buyers", response.data);
        setBuyers(response.data);
      },
      (error) => {
        setError(error);
      },
    );
    WarehouseService.getCargoPayers().then(
      (response) => {
        console.log("cargopayers", response.data);
        setCargoPayers(response.data);
      },
      (error) => {
        setError(error);
      },
    );

    WarehouseService.getCustomers().then(
      (response) => {
        response.data.sort(function (a: Customer, b: Customer) {
          let nameA = a.name.toUpperCase();
          let nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        setCustomers(
          response.data.filter((customer: Customer) => !customer.archived),
        );
      },
      (error) => {
        setError(error);
      },
    );
    WarehouseService.getMovies().then(
      (response) => {
        setMovies(response.data);
      },
      (error) => {
        setError(error);
      },
    );
    WarehouseService.getProducts().then(
      (response) => {
        setProducts(response.data);
      },
      (error) => {
        setError(error);
      },
    );
    WarehouseService.getProductTypes().then(
      (response) => {
        setProductTypes(response.data);
      },
      (error) => {
        setError(error);
      },
    );
    WarehouseService.getWarehouseInvoices().then(
      (response) => {
        setWarehouseInvoices(response.data);
      },
      (error) => {
        setError(error);
      },
    );
    WarehouseService.getDeliveries().then(
      (response) => {
        setDeliveries(response.data);
      },
      (error) => {
        setError(error);
      },
    );
  }, []);

  const getCustomerById = (customerId: number) => {
    return customers.find((customer) => {
      return customer.id === customerId;
    });
  };

  const getMovieById = (movieId: number) => {
    return movies.find((movie) => {
      return movie.id === movieId;
    });
  };

  const getMoviesByCustomer = (customerId: number) => {
    const movies = filteredMovies.filter((movie) => {
      return movie.customer === customerId;
    });
    return movies.sort(function (a: Movie, b: Movie) {
      let nameA = a.name.toUpperCase();
      let nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  };

  const getProductsByMovie = useCallback(
    (movieId: number) => {
      return products.filter((product) => {
        return product.movie.id === movieId;
      });
    },
    [products],
  );

  const getProductById = (productId: number) => {
    return products.find((product) => {
      return product.id === productId;
    });
  };

  const getWarehouseInvoicesByProductId = (productId: number) => {
    return warehouseInvoices.filter((invoice) => {
      return invoice.product.id === productId;
    });
  };

  const getLatestWarehouseInvoiceDate = (productId: number) => {
    const invoices = getWarehouseInvoicesByProductId(productId);
    invoices.sort(function (a, b) {
      const aDate = new Date(a.date).valueOf();
      const bDate = new Date(b.date).valueOf();
      return bDate - aDate;
    });
    return invoices[0] ? invoices[0].date : null;
  };

  const getSecondLatestWarehouseInvoiceDate = (productId: number) => {
    const invoices = getWarehouseInvoicesByProductId(productId);
    invoices.sort(function (a, b) {
      const aDate = new Date(a.date).valueOf();
      const bDate = new Date(b.date).valueOf();
      return bDate - aDate;
    });
    return invoices[1] ? invoices[1].date : null;
  };

  const getDeliveriesByProductId = useCallback(
    (productId: number) => {
      return deliveries.filter((delivery) => {
        return delivery.product === productId;
      });
    },
    [deliveries],
  );

  const onInvoiceDelivery = async (deliveryId: number) => {
    await WarehouseService.invoiceDelivery(deliveryId);
    const newDeliveries = await WarehouseService.getDeliveries();
    setDeliveries(newDeliveries.data);
  };

  const onWarehouseInvoice = async (productId: number) => {
    await WarehouseService.addWarehouseInvoice({
      product: productId,
      date: new Date().toISOString(),
    });
    const newWarehouseInvoices = await WarehouseService.getWarehouseInvoices();
    setWarehouseInvoices(newWarehouseInvoices.data);
  };

  const deleteCustomer = async (customerId: number) => {
    await WarehouseService.editCustomer({
      id: customerId,
      archived: true,
    });
    WarehouseService.getCustomers().then(
      (response) => {
        response.data.sort(function (a: Customer, b: Customer) {
          let nameA = a.name.toUpperCase();
          let nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        setCustomers(
          response.data.filter((customer: Customer) => !customer.archived),
        );
      },
      (error) => {
        setError(error);
      },
    );
    handleClose();
  };

  const deleteProduct = async (productId: number) => {
    await WarehouseService.deleteProduct(productId);
    const products = await WarehouseService.getProducts();
    setProducts(products.data);
    handleClose();
  };

  const deleteMovie = async (movieId: number) => {
    await WarehouseService.deleteMovie(movieId);
    const movies = await WarehouseService.getMovies();
    setMovies(movies.data);
    handleClose();
  };

  const isDeliveriesInvoiced = useCallback(
    (productId: number) => {
      const deliveries = getDeliveriesByProductId(productId);
      if (deliveries.length === 0) return false;
      const notInvoiced = deliveries.filter((delivery) => {
        return !delivery.invoiced;
      });
      return notInvoiced.length === 0;
    },
    [getDeliveriesByProductId],
  );

  const searchMovies = async (query: string) => {
    setSearch(query);
    if (query.length < 2) {
      setSearchedMovies([]);
      return;
    }
    const res = await WarehouseService.searchMovies(query);
    setSearchedMovies(res.data);
  };

  useEffect(() => {
    const searched: Movie[] = movies.filter((movie) => {
      if (search.length >= 2) {
        return (
          searchedMovies.findIndex((result) => {
            return result.id === movie.id;
          }) !== -1
        );
      } else return search.length < 2;
    });
    if (showPast) {
      setFilteredMovies(searched);
    } else {
      const filteredMovies = searched.filter((movie: Movie) => {
        const products = getProductsByMovie(movie.id);
        if (products.length === 0) return true;
        const filteredProducts = products.filter((product) => {
          if (isDeliveriesInvoiced(product.id) && product.current_stock === 0) {
            return false;
          } else {
            return true;
          }
        });
        return filteredProducts.length > 0;
      });
      setFilteredMovies(filteredMovies);
    }
  }, [
    form,
    showPast,
    search,
    movies,
    products,
    searchedMovies,
    getProductsByMovie,
    isDeliveriesInvoiced,
  ]);

  return (
    <Container fluid>
      {error ? <div>{error}</div> : null}
      <ModalForm
        show={show}
        handleClose={handleClose}
        modalState={modalState}
        form={form}
        onChangeFormValue={onChangeFormValue}
        onChangeDateValue={onChangeDateValue}
        onFormSubmit={onFormSubmit}
        deleteCustomer={deleteCustomer}
        deleteProduct={deleteProduct}
        deleteMovie={deleteMovie}
        productTypes={productTypes}
        buyers={buyers}
        cargoPayers={cargoPayers}
      />
      {!guest ? (
        <Row>
          <Col xs={12} className="mb-4 d-flex xs-12 justify-content-end">
            <Form.Group>
              <Form.Control
                onChange={(e) => searchMovies(e.target.value)}
                type="email"
                placeholder="Etsi elokuvaa"
              />
            </Form.Group>
            <Button onClick={() => setShowPast(!showPast)}>
              {showPast ? "Piilota" : "Näytä"} menneet
            </Button>
            <Button onClick={() => handleShow("add", "customer")}>
              Uusi asiakas
            </Button>
          </Col>
        </Row>
      ) : null}
      {
        /*hostname === "tilaus.tremendo.fi" &&*/ <Row>
          <Col>
            {customers &&
              customers.map((customer) => {
                return (
                  <Order
                    key={customer.id}
                    customer={customer}
                    movies={getMoviesByCustomer(customer.id)}
                    products={products}
                    deliveries={deliveries}
                    productTypes={productTypes}
                    warehouseInvoices={warehouseInvoices}
                    guest={guest}
                    handleShow={handleShow}
                    getProductsByMovie={getProductsByMovie}
                    getDeliveriesByProductId={getDeliveriesByProductId}
                    getLatestWarehouseInvoiceDate={
                      getLatestWarehouseInvoiceDate
                    }
                    getSecondLatestWarehouseInvoiceDate={
                      getSecondLatestWarehouseInvoiceDate
                    }
                    onInvoiceDelivery={onInvoiceDelivery}
                    onWarehouseInvoice={onWarehouseInvoice}
                    isDeliveriesInvoiced={isDeliveriesInvoiced}
                  />
                );
              })}
            ;
          </Col>
        </Row>
      }
      {false &&
        customers &&
        customers.map((customer) => {
          if (search !== "" && getMoviesByCustomer(customer.id).length === 0) {
            return null;
          }
          return (
            <CustomerRowComponent
              key={customer.id}
              customer={customer}
              movies={getMoviesByCustomer(customer.id)}
              products={products}
              deliveries={deliveries}
              productTypes={productTypes}
              warehouseInvoices={warehouseInvoices}
              guest={guest}
              handleShow={handleShow}
              getProductsByMovie={getProductsByMovie}
              getDeliveriesByProductId={getDeliveriesByProductId}
              getLatestWarehouseInvoiceDate={getLatestWarehouseInvoiceDate}
              getSecondLatestWarehouseInvoiceDate={
                getSecondLatestWarehouseInvoiceDate
              }
              onInvoiceDelivery={onInvoiceDelivery}
              onWarehouseInvoice={onWarehouseInvoice}
              isDeliveriesInvoiced={isDeliveriesInvoiced}
            />
          );
        })}
    </Container>
  );
};

export default Dashboard;
