export default function authHeader(): { Authorization: string } {
  const userLocalStorage = localStorage.getItem("user");
  const user = userLocalStorage ? JSON.parse(userLocalStorage) : null;

  if (user && user.access) {
    return { Authorization: "Bearer " + user.access };
  } else {
    return { Authorization: "" };
  }
}
