import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ReactDatePicker from "react-datepicker";
import {
  CustomerInput,
  MovieInput,
  ProductInput,
  DeliveryInput,
} from "../types/types";

type ModalProps = {
  show: boolean;
  handleClose: () => void;
  modalState: any;
  form: any;
  onChangeFormValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDateValue: (date: Date, field: string) => void;
  onFormSubmit: () => void;
  deleteCustomer: (customerId: number) => void;
  deleteProduct: (productId: number) => void;
  deleteMovie: (movieId: number) => void;
  productTypes: any[];
  buyers: any[];
  cargoPayers: any[];
};

const ModalForm: React.FC<ModalProps> = ({
  show,
  handleClose,
  modalState,
  form,
  onChangeFormValue,
  onChangeDateValue,
  onFormSubmit,
  deleteCustomer,
  deleteProduct,
  deleteMovie,
  productTypes,
  buyers,
  cargoPayers,
}) => {
  const { type, item, id } = modalState;

  const renderHeader = (header: string) => (
    <Modal.Header>
      {type === "edit"
        ? "Muokkaa"
        : type === "order"
          ? `Tilaa`
          : `Lisää ${header}`}
    </Modal.Header>
  );

  const renderFooter = (id?: number) => (
    <Modal.Footer>
      {id && type !== "order" ? (
        <Button
          variant="danger"
          onClick={() => {
            switch (modalState.item) {
              case "product":
                deleteProduct(id);
                break;
              case "movie":
                deleteMovie(id);
                break;
              case "customer":
                deleteCustomer(id);
                break;
            }
          }}
        >
          Poista
        </Button>
      ) : null}
      <Button variant="secondary" onClick={handleClose}>
        Sulje
      </Button>
      <Button variant="primary" onClick={onFormSubmit}>
        {type === "edit" ? "Tallenna" : type === "order" ? "Tilaa" : "Lisää"}
      </Button>
    </Modal.Footer>
  );

  const renderProductForm = () => {
    let editing = false;
    let order = type === "order" ? true : false;
    if (form.id) {
      editing = true;
    }
    return (
      <>
        <Form.Group>
          <Form.Label>Tuote</Form.Label>
          <Form.Control
            value={
              editing
                ? (form as ProductInput).product_type
                : (form as MovieInput).product?.product_type
            }
            disabled={order}
            as="select"
            id="productproduct_type"
            onChange={onChangeFormValue}
          >
            {productTypes.map((productType) => {
              return (
                <option key={productType.id} value={productType.id}>
                  {productType.name}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Tuotteen saapumispäivä</Form.Label>
          {modalState.item === "movie" ? (
            <ReactDatePicker
              dateFormat="dd.MM.yyyy"
              selected={
                new Date(
                  (form as MovieInput).product?.arrival_date || new Date(),
                )
              }
              onChange={(date: Date | null) =>
                onChangeDateValue(date as Date, "productarrival_date")
              }
            />
          ) : (
            modalState.type !== "order" && (
              <ReactDatePicker
                dateFormat="dd.MM.yyyy"
                selected={
                  new Date((form as ProductInput).arrival_date || new Date())
                }
                onChange={(date: Date | null) =>
                  onChangeDateValue(date as Date, "productarrival_date")
                }
              />
            )
          )}
        </Form.Group>

        {!editing ? (
          <Form.Group>
            <Form.Label>Montako tuotetta tuli?</Form.Label>
            <Form.Control
              id="productarrival_amount"
              placeholder="Määrä"
              onChange={onChangeFormValue}
            />
          </Form.Group>
        ) : null}

        <Form.Group>
          <Form.Label>Tämän hetkinen varastosaldo</Form.Label>
          <Form.Control
            disabled={order}
            value={
              editing
                ? (form as ProductInput).current_stock
                : (form as MovieInput).product?.current_stock
            }
            id="productcurrent_stock"
            placeholder="Saldo"
            onChange={onChangeFormValue}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Varastosijainti</Form.Label>
          <Form.Control
            value={
              editing
                ? (form as ProductInput).shelving_location
                : (form as MovieInput).product?.shelving_location
            }
            id="productshelving_location"
            placeholder="Sijainti"
            onChange={onChangeFormValue}
          />
        </Form.Group>
      </>
    );
  };

  switch (item) {
    case "customer":
      const customerInput = form as CustomerInput;
      return (
        <Modal show={show} onHide={handleClose}>
          {renderHeader("asiakas")}
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Asiakkaan nimi</Form.Label>
                <Form.Control
                  value={customerInput.name ?? ""}
                  id="name"
                  placeholder="nimi"
                  onChange={onChangeFormValue}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          {renderFooter(id)}
        </Modal>
      );
    case "movie":
      const movieInput = form as MovieInput;
      return (
        <Modal show={show} onHide={handleClose}>
          {renderHeader("elokuva")}
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Elokuvan nimi</Form.Label>
                <Form.Control
                  value={movieInput.name ?? ""}
                  id="name"
                  placeholder="nimi"
                  onChange={onChangeFormValue}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Ensi-ilta</Form.Label>
                {
                  // @ts-ignore
                  <ReactDatePicker
                    dateFormat="dd.MM.yyyy"
                    selected={
                      new Date((form as MovieInput).premiere_date) || new Date()
                    }
                    onChange={(date: Date | null) => {
                      onChangeDateValue(date as Date, "premiere_date");
                    }}
                  />
                }
              </Form.Group>

              <Form.Group>
                <Form.Label>ID</Form.Label>
                <Form.Control
                  value={movieInput.secret ?? ""}
                  id="secret"
                  placeholder="ID"
                  onChange={onChangeFormValue}
                />
              </Form.Group>
              {!form.id ? (
                <div>
                  Tuotetiedot:
                  {renderProductForm()}
                </div>
              ) : null}
            </Form>
          </Modal.Body>
          {renderFooter(id)}
        </Modal>
      );
    case "product":
      return (
        <Modal show={show} onHide={handleClose}>
          {renderHeader("tuote")}
          <Modal.Body>
            <Form>{renderProductForm()}</Form>
          </Modal.Body>
          {renderFooter(id)}
        </Modal>
      );
    case "delivery":
      let order = type === "order";
      return (
        <Modal show={show} onHide={handleClose}>
          {renderHeader(type === "order" ? "Tilaus" : "toimitus")}
          <Modal.Body>
            <Form>
              {!order && (
                <Form.Group>
                  <Form.Label>Lähetyspäivämäärä</Form.Label>
                  <br />
                  {
                    // @ts-ignore
                    <ReactDatePicker
                      dateFormat="dd.MM.yyyy"
                      selected={
                        new Date((form as DeliveryInput).delivery_date) ||
                        new Date()
                      }
                      onChange={(date: Date | null) => {
                        onChangeDateValue(date as Date, "delivery_date");
                      }}
                    />
                  }
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label>Minne</Form.Label>
                <Form.Control
                  id="to"
                  placeholder="Minne"
                  onChange={onChangeFormValue}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Paikkojen määrä</Form.Label>
                <Form.Control
                  disabled={order}
                  id="location_amount"
                  placeholder="Paikkojen määrä"
                  value={!order ? (form as DeliveryInput).location_amount : "1"}
                  onChange={onChangeFormValue}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Määrä</Form.Label>
                <Form.Control
                  id="amount"
                  placeholder="Määrä"
                  onChange={onChangeFormValue}
                />
              </Form.Group>
              {order && (
                <>
                  <Form.Group>
                    <Form.Label>Tilaaja</Form.Label>
                    <Form.Control
                      value={(form as DeliveryInput).buyer ?? ""}
                      as="select"
                      id="buyer"
                      onChange={onChangeFormValue}
                    >
                      {buyers.map((buyer) => {
                        return (
                          <option key={buyer.id} value={buyer.id}>
                            {buyer.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Rahdin maksaja</Form.Label>
                    <Form.Control
                      value={(form as DeliveryInput).cargo_payer ?? ""}
                      as="select"
                      id="cargo_payer"
                      onChange={onChangeFormValue}
                    >
                      {cargoPayers.map((buyer) => {
                        return (
                          <option key={buyer.id} value={buyer.id}>
                            {buyer.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </>
              )}
            </Form>
          </Modal.Body>
          {renderFooter()}
        </Modal>
      );
    default:
      return null;
  }
};

export default ModalForm;
