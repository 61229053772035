import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { BuyerInput, CargoPayerInput, UserInput } from "../../types/types";

const AddBuyer = (props: any) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const [buyerName, setBuyerName] = useState("");
  const [buyerAddress, setBuyerAddress] = useState("");
  const [buyerPostalCode, setBuyerPostalCode] = useState("");
  const [buyerCity, setBuyerCity] = useState("");
  const [buyerPhone, setBuyerPhone] = useState("");
  const [buyerPostalCustomerNumber, setBuyerPostalCustomerNumber] =
    useState("");
  const [buyerMatkahuoltoClientNumber, setBuyerMatkahuoltoClientNumber] =
    useState("");

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setBuyerName(name);
  };

  const onChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const address = e.target.value;
    setBuyerAddress(address);
  };

  const onChangePostalCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const postalCode = e.target.value;
    setBuyerPostalCode(postalCode);
  };

  const onChangeCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const city = e.target.value;
    setBuyerCity(city);
  };

  const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phone = e.target.value;
    setBuyerPhone(phone);
  };

  const onChangePostalCustomerNumber = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const postalCustomerNumber = e.target.value;
    setBuyerPostalCustomerNumber(postalCustomerNumber);
  };

  const onChangeMatkahuoltoClientNumber = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const matkahuoltoClientNumber = e.target.value;
    setBuyerMatkahuoltoClientNumber(matkahuoltoClientNumber);
  };

  const handleAddBuyer = () => {
    setMessage("");
    setLoading(true);

    const user: UserInput = {
      username: username,
    };

    const buyer: BuyerInput = {
      name: buyerName,
      user: user,
      contact_name: buyerName,
      contact_email: email,
      contact_phone: buyerPhone,
      street_address: buyerAddress,
      postal_code: buyerPostalCode,
      city: buyerCity,
      matkahuolto_client_number: buyerMatkahuoltoClientNumber,
      posti_client_number: buyerPostalCustomerNumber,
    };

    const cargoPayer: CargoPayerInput = {
      name: buyerName,
      buyer: buyer,
    };

    /*    warehouseService.addUser(user).then(
      (data) => {
        buyer.userId = data.data.id;
        warehouseService.addContactPerson(cargoPayer).then(
          (data) => {
            buyer.contact_person = data.data.id;
            warehouseService.addBuyer(buyer).then(
              () => {
                props.history.push("/dashboard");
                window.location.reload();
              },
              (error: any) => {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();

                setLoading(false);
                setMessage(resMessage);
              },
            );
          },
          (error: any) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            setLoading(false);
            setMessage(resMessage);
          },
        );
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      },
    ); */
  };

  return (
    <Container>
      <Row>
        <Col>
          <Form>
            <Form.Group>
              <Form.Label>Käyttäjätunnus</Form.Label>
              <Form.Control
                placeholder="Käyttäjätunnus"
                onChange={onChangeUsername}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control placeholder="Email" onChange={onChangeEmail} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Nimi</Form.Label>
              <Form.Control placeholder="Nimi" onChange={onChangeName} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Puhelin</Form.Label>
              <Form.Control placeholder="Puhelin" onChange={onChangePhone} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Osoite</Form.Label>
              <Form.Control placeholder="Osoite" onChange={onChangeAddress} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Postinumero</Form.Label>
              <Form.Control
                placeholder="Postinumero"
                onChange={onChangePostalCode}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Kaupunki</Form.Label>
              <Form.Control placeholder="Kaupunki" onChange={onChangeCity} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Postin asiakasnumero</Form.Label>
              <Form.Control
                placeholder="Postin asiakasnumero"
                onChange={onChangePostalCustomerNumber}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Matkahuollon asiakasnumero</Form.Label>
              <Form.Control
                placeholder="Matkahuollon asiakasnumero"
                onChange={onChangeMatkahuoltoClientNumber}
              />
            </Form.Group>

            <Form.Group>
              {loading ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                <Button onClick={handleAddBuyer}>Lisää ostaja</Button>
              )}
            </Form.Group>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AddBuyer;
