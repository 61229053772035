import React from "react";
import Button from "react-bootstrap/Button";
import { format } from "date-fns";
import { Movie, Product, Delivery, WarehouseInvoice } from "../types/types";
import ProductRowComponent from "./ProductRowComponent";

type MovieRowProps = {
  movie: Movie;
  products: Product[];
  deliveries: Delivery[];
  warehouseInvoices: WarehouseInvoice[];
  guest: boolean;
  handleShow: (
    type: string,
    item: string,
    id?: number,
    customer?: number,
    movie?: number,
    product?: number,
  ) => void;
  getProductsByMovie: (movieId: number) => Product[];
  getDeliveriesByProductId: (productId: number) => Delivery[];
  getLatestWarehouseInvoiceDate: (productId: number) => string | null;
  getSecondLatestWarehouseInvoiceDate: (productId: number) => string | null;
  onInvoiceDelivery: (deliveryId: number) => void;
  onWarehouseInvoice: (productId: number) => void;
  order?: boolean | undefined;
};

const MovieRowComponent: React.FC<MovieRowProps> = ({
  movie,
  products,
  deliveries,
  warehouseInvoices,
  guest,
  handleShow,
  getProductsByMovie,
  getDeliveriesByProductId,
  getLatestWarehouseInvoiceDate,
  getSecondLatestWarehouseInvoiceDate,
  onInvoiceDelivery,
  onWarehouseInvoice,
  order,
}) => {
  const movieProducts = getProductsByMovie(movie.id);
  return (
    <>
      {movieProducts.length > 0 ? (
        movieProducts.map((product) => (
          <ProductRowComponent
            key={product.id}
            product={product}
            deliveries={deliveries}
            warehouseInvoices={warehouseInvoices}
            guest={guest}
            handleShow={handleShow}
            getDeliveriesByProductId={getDeliveriesByProductId}
            getLatestWarehouseInvoiceDate={getLatestWarehouseInvoiceDate}
            getSecondLatestWarehouseInvoiceDate={
              getSecondLatestWarehouseInvoiceDate
            }
            onInvoiceDelivery={onInvoiceDelivery}
            onWarehouseInvoice={onWarehouseInvoice}
            order={order ? order : false}
          />
        ))
      ) : (
        <tr key={movie.id + movie.name}>
          <td>
            {movie.secret}
            {movie.secret ? <br /> : null}
            <Button
              style={{
                paddingLeft: 0,
                paddingTop: 0,
              }}
              size="sm"
              variant="link"
              onClick={() =>
                handleShow("edit", "movie", movie.id, movie.customer)
              }
            >
              Muokkaa
            </Button>
          </td>
          <td>{movie.name}</td>
          <td>{format(new Date(movie.premiere_date), "dd.MM.yyyy")}</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          {!guest ? (
            <td>
              <Button
                size="sm"
                onClick={() =>
                  handleShow("add", "product", undefined, undefined, movie.id)
                }
              >
                Uusi tuote
              </Button>
            </td>
          ) : null}
        </tr>
      )}
    </>
  );
};

export default MovieRowComponent;
