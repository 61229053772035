import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { login } from "services/api/auth.service";
import { useNavigate } from "react-router-dom";

function Login() {
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = () => {
    setMessage("");
    setLoading(true);

    login(username, password).then(
      () => {
        navigate("/dashboard");
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      },
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <Form>
            <Form.Group>
              <Form.Label>Käyttäjätunnus</Form.Label>
              <Form.Control
                placeholder="Käyttäjätunnus"
                onChange={onChangeUsername}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Salasana</Form.Label>
              <Form.Control
                type="password"
                placeholder="Salasana"
                onChange={onChangePassword}
              />
            </Form.Group>

            <Form.Group>
              {loading ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                <Button onClick={handleLogin}>Kirjaudu</Button>
              )}
            </Form.Group>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
