import axios from "axios";
import authHeader from "./auth-header";

const apiClient = axios.create({
  baseURL: "http://localhost:8000", // "https://api.tremendo.fi" ||
  headers: { "Content-Type": "application/json" },
});

apiClient.interceptors.request.use((config) => {
  const auth = authHeader();
  config.headers.Authorization = auth["Authorization"];
  return config;
});

export default apiClient;
