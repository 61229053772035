import React from "react";
import Button from "react-bootstrap/Button";
import { format, differenceInCalendarDays } from "date-fns";
import { Product, Delivery, WarehouseInvoice } from "../types/types";

type ProductRowProps = {
  product: Product;
  deliveries: Delivery[];
  warehouseInvoices: WarehouseInvoice[];
  guest: boolean;
  handleShow: (
    type: string,
    item: string,
    id?: number,
    customer?: number,
    movie?: number,
    product?: number,
  ) => void;
  getDeliveriesByProductId: (productId: number) => Delivery[];
  getLatestWarehouseInvoiceDate: (productId: number) => string | null;
  getSecondLatestWarehouseInvoiceDate: (productId: number) => string | null;
  onInvoiceDelivery: (deliveryId: number) => void;
  onWarehouseInvoice: (productId: number) => void;
  order?: boolean;
};

const ProductRowComponent: React.FC<ProductRowProps> = ({
  product,
  deliveries,
  warehouseInvoices,
  guest,
  handleShow,
  getDeliveriesByProductId,
  getLatestWarehouseInvoiceDate,
  getSecondLatestWarehouseInvoiceDate,
  onInvoiceDelivery,
  onWarehouseInvoice,
  order,
}) => {
  const deliveriesMap = getDeliveriesByProductId(product.id).map((delivery) => {
    return (
      <div key={delivery.id}>
        {delivery.location_amount} ({delivery.to}): {delivery.amount}kpl (
        {format(new Date(delivery.delivery_date), "dd.MM.yyyy")})
        {!delivery.invoiced ? (
          <Button
            variant="link"
            style={{ padding: 0 }}
            onClick={() => onInvoiceDelivery(delivery.id)}
          >
            ✅
          </Button>
        ) : (
          "❌"
        )}
      </div>
    );
  });

  const warehouseInvoiceDate: string | null | Date =
    getLatestWarehouseInvoiceDate(product.id);
  const warehouseSecondInvoiceDate: string | null | Date =
    getSecondLatestWarehouseInvoiceDate(product.id);
  const warehouseInvoiceDate2: Date = warehouseInvoiceDate
    ? new Date(warehouseInvoiceDate)
    : new Date(product.arrival_date);
  const warehouseDays =
    product.current_stock === 0
      ? 0
      : warehouseInvoiceDate
        ? differenceInCalendarDays(new Date(), warehouseInvoiceDate2)
        : differenceInCalendarDays(new Date(), warehouseInvoiceDate2) - 60 > 0
          ? differenceInCalendarDays(new Date(), warehouseInvoiceDate2) - 60
          : 0;
  const warehouseDaysBefore = warehouseInvoiceDate2 ? (
    warehouseSecondInvoiceDate ? (
      <div>
        {format(new Date(warehouseInvoiceDate2), "dd.MM.yyyy")}:
        {differenceInCalendarDays(
          warehouseInvoiceDate2,
          new Date(warehouseSecondInvoiceDate),
        )}
      </div>
    ) : (
      <div>
        {format(new Date(warehouseInvoiceDate2), "dd.MM.yyyy")}:
        {differenceInCalendarDays(
          warehouseInvoiceDate2,
          new Date(product.arrival_date),
        )}
      </div>
    )
  ) : null;
  return (
    <tr key={product.id}>
      <td>{product.movie.secret}</td>
      <td>{product.movie.name}</td>
      {!guest ? <td>{product.shelving_location}</td> : null}
      <td>
        <div className="text-td">
          {format(new Date(product.movie.premiere_date), "dd.MM.yyyy")}
        </div>
      </td>
      <td>
        <div className="text-td">
          {format(new Date(product.arrival_date), "dd.MM.yyyy")}
        </div>
      </td>
      <td>
        <div className="text-td">{product.product_type.name}</div>
      </td>
      <td>
        <div className="text-td">{product.current_stock}</div>
      </td>
      {!guest ? (
        <td>
          <div
            style={{
              color: warehouseDays > 60 ? "red" : "black",
            }}
            className="text-td"
          >
            {warehouseDays}{" "}
            <Button
              variant="link"
              style={{ padding: 0 }}
              onClick={() => onWarehouseInvoice(product.id)}
            >
              ✅
            </Button>
            <br />
            {warehouseDaysBefore}
          </div>
        </td>
      ) : null}
      {!guest ? (
        <td>
          {deliveriesMap}
          <div>
            <Button
              size="sm"
              onClick={() =>
                handleShow(
                  "add",
                  "delivery",
                  undefined,
                  undefined,
                  undefined,
                  product.id,
                )
              }
            >
              Jaa
            </Button>
          </div>
        </td>
      ) : null}
      {!guest ? (
        <td>
          <Button
            size="sm"
            className="mr-2"
            onClick={() => handleShow("edit", "product", product.id)}
          >
            Muokkaa
          </Button>
        </td>
      ) : (
        order && (
          <td>
            <Button
              size="sm"
              className="mr-2"
              onClick={() => handleShow("order", "delivery", product.id)}
            >
              Tilaa
            </Button>
          </td>
        )
      )}
    </tr>
  );
};

export default ProductRowComponent;
