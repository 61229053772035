import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import {
  Customer,
  Movie,
  Product,
  Delivery,
  ProductType,
  WarehouseInvoice,
} from "../../types/types";
import MovieRowComponent from "../../components/MovieRowComponent";

function CustomToggle({
  children,
  eventKey,
}: {
  children: React.ReactNode;
  eventKey: string;
}) {
  const decoratedOnClick = useAccordionButton(eventKey);
  return (
    <Accordion.Button
      bsPrefix="super-toggle"
      style={{
        textAlign: "left",
        maxWidth: "100%",
        display: "block",
        position: "relative",
        padding: ".75rem 1.25rem",
      }}
      as={Col}
      onClick={decoratedOnClick}
    >
      {children}
    </Accordion.Button>
  );
}

type OrderRowProps = {
  customer: Customer;
  movies: Movie[];
  products: Product[];
  deliveries: Delivery[];
  productTypes: ProductType[];
  warehouseInvoices: WarehouseInvoice[];
  guest: boolean;
  handleShow: (
    type: string,
    item: string,
    id?: number,
    customer?: number,
    movie?: number,
    product?: number,
  ) => void;
  getProductsByMovie: (movieId: number) => Product[];
  getDeliveriesByProductId: (productId: number) => Delivery[];
  getLatestWarehouseInvoiceDate: (productId: number) => string | null;
  getSecondLatestWarehouseInvoiceDate: (productId: number) => string | null;
  onInvoiceDelivery: (deliveryId: number) => void;
  onWarehouseInvoice: (productId: number) => void;
  isDeliveriesInvoiced: (productId: number) => boolean;
};

const Order: React.FC<OrderRowProps> = ({
  customer,
  movies,
  products,
  deliveries,
  productTypes,
  warehouseInvoices,
  guest,
  handleShow,
  getProductsByMovie,
  getDeliveriesByProductId,
  getLatestWarehouseInvoiceDate,
  getSecondLatestWarehouseInvoiceDate,
  onInvoiceDelivery,
  onWarehouseInvoice,
  isDeliveriesInvoiced,
}) => {
  const getMoviesByCustomer = (customerId: number) => {
    const filteredMovies = movies.filter((movie) => {
      return movie.customer === customerId;
    });
    return filteredMovies.sort((a, b) => {
      let nameA = a.name.toUpperCase();
      let nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  };

  return (
    <Row key={customer.name} className="mb-3">
      <Col>
        <Accordion>
          <Card>
            <Card.Header>
              <Row style={{ textAlign: "left" }}>
                <div style={{ display: "flex" }}>
                  <CustomToggle eventKey="0">{customer.name}</CustomToggle>
                </div>
              </Row>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Row>
                  <Col
                    xs={12}
                    className="mb-4 d-flex xs-12 justify-content-end"
                  ></Col>
                </Row>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nimi</th>
                      {!guest ? <th>Varasto paikka</th> : null}
                      <th>Ensi-ilta</th>
                      <th>Saapunut</th>
                      <th>Tyyppi</th>
                      <th>Saldo</th>
                      {!guest ? <th>Päivät varastolla</th> : null}
                      {!guest ? <th>Jako päivät ja määrät</th> : null}
                      {!guest ? <th>Muokkaus</th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {getMoviesByCustomer(customer.id).map((movie) => (
                      <MovieRowComponent
                        key={movie.id}
                        movie={movie}
                        products={products}
                        deliveries={deliveries}
                        warehouseInvoices={warehouseInvoices}
                        guest={guest}
                        handleShow={handleShow}
                        getProductsByMovie={getProductsByMovie}
                        getDeliveriesByProductId={getDeliveriesByProductId}
                        getLatestWarehouseInvoiceDate={
                          getLatestWarehouseInvoiceDate
                        }
                        getSecondLatestWarehouseInvoiceDate={
                          getSecondLatestWarehouseInvoiceDate
                        }
                        onInvoiceDelivery={onInvoiceDelivery}
                        onWarehouseInvoice={onWarehouseInvoice}
                        order={true}
                      />
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Col>
    </Row>
  );
};

export default Order;
